
const cmsRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/modules/frontend/views/cms/LoginPage/index.vue'),
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('@/modules/frontend/views/cms/SignupPage.vue'),
    },
]
const contentRoutes = [
    {
        path: 'article/:id',
        name: 'ArticleDetails',
        component: () => import('@/modules/frontend/views/content/ArticleDetails/index.vue'),
        props: true,
    },
    {
        path: 'article',
        name: 'ArticleList',
        component: () => import('@/modules/frontend/views/content/ArticleList/index.vue'),
        props: (route) => ({
            kw: route.query.kw || '', // 将查询参数 kw 传递到组件
            categoryID: route.query.categoryID || '', // 将查询参数 categoryID 传递到组件
        }),
    },
]

const housingRoutes = [
    {
        path: '/properties',
        name: 'PropertyList',
        component: () => import('@/modules/frontend/views/housing/PropertyList/index.vue'),
        props: (route) => ({
            kw: route.query.kw || '', // 将查询参数 kw 传递到组件
            cityID: route.query.cityID || '', // 将查询参数 cityID 传递到组件
            districtID: route.query.districtID || '', // 将查询参数 districtID 传递到组件
            order: route.query.order || '' // 将查询参数 order 传递到组件
        }),
    },
    {
        path: 'property/:id',
        name: 'PropertyDetails',
        component: () => import('@/modules/frontend/views/housing/PropertyDetails/index.vue'),
        props: true,
    },
    {
        path: 'park',
        name: 'ParkDetails',
        component: () => import('@/modules/frontend/views/housing/ParkDetails.vue'),
    },
    {
        path: 'upload',
        name: 'SocketUpload',
        component: () => import('@/modules/frontend/views/housing/SocketUpload/index.vue'),
    },
]

const toolBoxRoutes = [
    {
        path: 'all',
        name: 'ToolBoxList',
        component: () => import('@/modules/frontend/views/toolbox/ToolBoxList/index.vue'),
    },
]

const frontEndRoutes = [
    {
        path: '',
        name: 'Home',
        component: () => import('@/modules/frontend/views/HomePage/index.vue'),
    },
    {
        path: '/cms',
        children: cmsRoutes,
    },
    {
        path: '/housing',
        children: housingRoutes,
    },
    {
        path: '/content',
        children: contentRoutes,
    },
    {
        path: '/toolbox',
        children: toolBoxRoutes,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('@/modules/frontend/views/NotFound.vue')
    },
];

export default frontEndRoutes;