/**
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
 | 沪上工业通 - 上海找厂房 就上工业通
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 版权所有 © 2024-2025 上海聚未投资管理有限公司
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 本平台及其相关内容受《中华人民共和国著作权法》及相关法律法规保护。
 | 未经上海聚未投资管理有限公司书面授权，任何单位或个人不得以任何形式
 | 复制、修改、传播或用于商业用途。
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 | 官方网站：www.gongyetong.cn
 +- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
*/

// src/main.js

import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import { VueShowdownPlugin } from 'vue-showdown';


import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './assets/css/main.css'
import { sendErrorEmail } from "@/services/utils";

const app = createApp(App);

// 挂载 sendErrorEmail 函数为全局函数
app.config.globalProperties.$sendErrorEmail = sendErrorEmail;

// 注册 VueShowdownPlugin
app.use(VueShowdownPlugin, {
  // 设置 showdown 默认 flavor
  flavor: 'original',
  // 设置 showdown 默认 options （会覆盖上面 flavor 的 options）
  options: {
    emoji: false,
  },
});

// 注册 Element Plus 图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(ElementPlus);

// 挂载 router
app.use(router).mount('#app');

